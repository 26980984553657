import { PopoverTrigger } from "@chakra-ui/react"
import { SmileyIcon } from "@mybridge/icons"
import { Emoji } from "@mybridge/ui/emoji"
import { IconButton } from "@mybridge/ui/icon-button"
import { Box } from "@mybridge/ui/layout"
import { Popover, PopoverContent } from "@mybridge/ui/popover"
import { useDisclosure } from "@mybridge/ui/hooks"
import { Tooltip } from "@mybridge/ui/tooltip"
import { useContext } from "react"
import { PostCommentFormContext } from "../context"

export const EmojiPicker = ({ }) => {
    const { setText, text, textInputRef } = useContext(PostCommentFormContext)
    const emojiDisc = useDisclosure()
    const insertAtPos = (value) => {
        const { current: ref } = textInputRef ?? {};
        if (ref) {
            let startPos = ref.selectionStart;
            let endPos = ref.selectionEnd;
            const text_ = ref.value.substring(0, startPos) + value.native + ref.value.substring(endPos, ref.value.length)
            setText(text_);
        }
    };
    return (
        <>
            <Popover {...emojiDisc}>
                <PopoverTrigger>
                    <Box>
                        <Tooltip variant="round" label="Add Emoji">
                            <IconButton icon={<SmileyIcon />} />
                        </Tooltip>
                    </Box>
                </PopoverTrigger>
                <PopoverContent>
                    {emojiDisc?.isOpen ? <Emoji onEmojiSelect={insertAtPos} /> : ""}
                </PopoverContent>
            </Popover>
        </>
    )
}