import { PopoverTrigger } from "@chakra-ui/react"
import { GifIcon } from "@mybridge/icons"
import Giphy from "@mybridge/ui/giphy"
import { useDisclosure } from "@mybridge/ui/hooks"
import { IconButton } from "@mybridge/ui/icon-button"
import { Box } from "@mybridge/ui/layout"
import { Popover, PopoverBody, PopoverContent } from "@mybridge/ui/popover"
import { Tooltip } from "@mybridge/ui/tooltip"
import { useContext } from "react"
import { PostCommentFormContext } from "../context"

export const GifPicker = ({ hideOnSelect = true }) => {
    const { setGifs, images, videos } = useContext(PostCommentFormContext)
    const { isOpen, onClose, onToggle } = useDisclosure()
    const onSelect = (gif) => {
        setGifs?.([gif])
        if (hideOnSelect) {
            onClose()
        }
    }
    return (
        <>
            <Popover
                isOpen={isOpen}
                onClose={onClose}
            >
                <PopoverTrigger>
                    <Box>
                        <Tooltip variant="round" label="Add Gif">
                            <IconButton isDisabled={images?.length || videos?.length} onClick={onToggle} icon={<GifIcon />} />
                        </Tooltip>
                    </Box>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverBody>
                        {isOpen ? <Giphy onSelect={onSelect} /> : ""}
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    )
}