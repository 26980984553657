import { CloseButton } from '@mybridge/ui/close-button';
import { Image } from '@mybridge/ui/image';
import { VideoPlayer } from '@mybridge/ui/video-player';
import { MediaSlider, MediaSliderItem } from '@mybridge/ui/media-slider';
import { useContext, useEffect, useState } from 'react';
import { fileToDataURL } from 'v4/lib/file';
import { PostCommentFormContext, PostFormContext } from '../context';
import { Box } from '@mybridge/ui/layout';
import styles from './index.module.scss';

export const AttachedMediaContainer = ({}) => {
  const { text, images, videos, gifs, removeImage, removeVideo, removeGif } =
    useContext(PostCommentFormContext);
  const [images_, setImages_] = useState([]);
  const [videos_, setVideos_] = useState([]);
  const [gifs_, setGifs_] = useState([]);

  useEffect(() => {
    prepareImages();
  }, [images]);

  useEffect(() => {
    prepareVideos();
  }, [videos]);

  useEffect(() => {
    prepareGifs();
  }, [gifs]);

  const prepareVideos = async () => {
    const vids = [];
    for (let vid of videos) {
      vids.push(typeof vid === 'string' ? vid : await fileToDataURL(vid));
    }
    setVideos_(vids);
  };

  const prepareImages = async () => {
    const imgs = [];
    for (let img of images) {
      imgs.push(typeof img === 'string' ? img : await fileToDataURL(img));
    }
    setImages_(imgs);
  };

  const prepareGifs = async () => {
    const gs = [];
    for (let gif of gifs) {
      gs.push(gif?.images?.original?.url);
    }
    setGifs_(gs);
  };

  const handleRemoveImage = (file, index) => {
    removeImage?.(file, index);
  };

  const handleRemoveVideo = (file, index) => {
    removeVideo?.(file, index);
  };

  const handleRemoveGif = (file, index) => {
    removeGif?.(file, index);
  };

  return (
    <>
      {videos_?.length ? (
        <Box
          pos="relative"
          borderRadius="5"
          overflow="hidden"
          className={styles.videoWrapper}
        >
          <CloseButton
            variant="round"
            zIndex={3}
            onClick={() => handleRemoveVideo(videos_?.[0], 0)}
            bg="white"
            pos="absolute"
            top={2}
            right={2}
          />
          <VideoPlayer
            height=""
            width=""
            style={{ backgroundColor: 'black' }}
            src={videos_?.[0]}
          />
        </Box>
      ) : (
        <>
          {images_?.length ? (
            <MediaSlider>
              {images_?.map?.((img, index) => (
                <MediaSliderItem
                  key={index}
                  minW="200px"
                  maxH="200px"
                  bg="blackAlpha.800"
                >
                  <CloseButton
                    variant="round"
                    onClick={() => handleRemoveImage(img, index)}
                    bg="white"
                    pos="absolute"
                    top={2}
                    right={2}
                  />
                  <Image src={img} />
                </MediaSliderItem>
              ))}
            </MediaSlider>
          ) : (
            <>
              {gifs_?.length ? (
                <Box maxH="300px" overflowY="auto" borderRadius="5">
                  <Box pos="relative">
                    <CloseButton
                      variant="round"
                      onClick={() => handleRemoveGif(gifs_?.[0], 0)}
                      bg="white"
                      pos="absolute"
                      top={2}
                      right={2}
                    />
                    <Image w="100%" src={gifs_?.[0]} />
                  </Box>
                </Box>
              ) : (
                ''
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
