import { ImageIcon } from "@mybridge/icons"
import { IconButton } from "@mybridge/ui/icon-button"
import { Input } from "@mybridge/ui/input"
import { Box, HStack } from "@mybridge/ui/layout"
import { Tooltip } from "@mybridge/ui/tooltip"
import { useContext, useRef } from "react"
import { PostCommentFormContext } from "../context"

export const MediaPicker = ({ ...props }) => {
    const { gifs, images, videos, setVideos, setImages } = useContext(PostCommentFormContext)
    const fileInputRef = useRef()

    const handleFilesChange = (e) => {
        const { files } = e?.target ?? {}
        const filesArr = Array.from(files)
        const video = filesArr?.find?.(f => f.type?.startsWith("video/"))
        if (video) {
            setVideos([video])
            setImages([])
        } else {
            const endIndex = 5 - images.length;
            setImages([...(images ?? []), ...(filesArr.slice(0, Math.min(filesArr.length, endIndex)))])
            setVideos([])
        }
        setTimeout(() => {
            if (fileInputRef.current) {
                fileInputRef.current.value = null
            }
        }, 100)
    }

    return (
        <HStack spacing={0}>
            <Tooltip hasArrow placement="top" variant="round" label="Add Images/Video">
                <IconButton isDisabled={gifs.length > 0 || videos?.length > 0 || images?.length >= 5} onClick={() => fileInputRef?.current?.click?.()}>
                    <ImageIcon />
                </IconButton>
            </Tooltip>
            <Box w="0" h="0" overflow="hidden">
                <Input multiple onChange={handleFilesChange} ref={fileInputRef} w={0} h={0} visibility="hidden" type="file" />
            </Box>
        </HStack>
    )
}