import {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  editComment,
  postComment,
} from 'packages/mybridgev4/store/actions/comment.actions';
import { PostCommentRowContext } from 'v4/components/comment/comment-row/context';
import { PostBoxContext } from 'v4/components/post/context';


export const PostCommentFormContext = createContext({});

export const usePostCommentFormContext = ({
  post,
  parent,
  refetch,
  showComments,
  level,
  comment,
  onCreate,
  onSave,
}) => {
  const [mode, setMode] = useState('create');
  const [text, setText] = useState('');
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [tagged, setTagged] = useState([]);
  const [visibility, setVisibility] = useState(0);
  const [replyControl, setReplyControl] = useState(0);
  const [gifs, setGifs] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const textInputRef = useRef();
  const { commentBoxExpanded, setCommentBoxExapnded } = useContext(
    PostCommentRowContext
  );
  const {
    refetch: postRefetch,
    commentBoxVisible,
    setCommentBoxVisible,
  } = useContext(PostBoxContext);
  const { userProfileInfo, currentProfileCommentData } = useSelector((state) => state.userProfile);
  useEffect(() => {
    if (comment?.id) {
      setMode('edit');
      setExpanded(true);
      const { body, gif, media } = comment ?? {};
      if (body?.length) {
        setText(body);
      }
      if (gif?.length) {
        setGifs([
          {
            images: { original: { url: gif } },
          },
        ]);
      }
      if (media?.length) {
        const hasVid = media?.find?.((m) =>
          m?.file?.toLowerCase?.()?.includes?.('mp4')
        );
        if (hasVid) {
          setVideos([media?.[0]?.file]);
        } else {
          setImages(media?.map?.((m) => m?.file));
        }
      }
    }
  }, [comment]);

  const addImage = (file) => {
    images.push(file);
    setImages([...images]);
  };

  const removeImage = (file, index) => {
    const images_ = images?.filter((_, ind) => ind !== index);
    setImages(images_);
  };

  const addGif = (file) => {
    gifs.push(file);
    setGifs([...gifs]);
  };

  const removeGif = (file, index) => {
    const gifs_ = gifs?.filter((_, ind) => ind !== index);
    setGifs(gifs_);
  };

  const addVideo = (file) => {
    videos.push(file);
    setVideos([...videos]);
  };

  const removeVideo = (file, index) => {
    const videos_ = videos?.filter((_, ind) => ind !== index);
    setVideos(videos_);
  };

  const createComment = (formData) => {
    setIsLoading(true);
    dispatch(postComment({ id: post?.id, formData }))
      .then(async () => {
        resetForm();
        await refetch?.();
        await postRefetch?.();
        setIsLoading(false);
        onCreate?.();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const edit = (formData) => {
    setIsLoading(true);
    dispatch(editComment({ id: comment?.id, formData }))
      .then(async () => {
        resetForm();
        await refetch?.();
        await postRefetch?.();
        setIsLoading(false);
        onSave?.();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleSubmit = (currentPostCommenter) => {
    const formData = new FormData();
    if (!post || !post.id) {
      console.log('Invalid post...');
      return;
    }
    if (parent) {
      formData.append('parent_comment', parent?.id);
    }

    // if text is more than 300 characters then return
    if (text?.length > 300) {
      return;
    }

    if (text?.length > 0) {
      formData.append('body', text);
    } else {
      formData.append('body', '‏‏‎ ‎');
    }

    if (tagged && tagged?.length) {
      tagged?.forEach?.((t) => formData.append('tagged_user_ids', t?.id));
    }

    if (images?.length) {
      images.forEach((image) => {
        formData.append('media', image);
      });
    }

    if (gifs?.length) {
      formData.append('gif', gifs?.[0]?.images?.original?.url);
    }

    if (videos?.length) {
      formData.append('media', videos?.[0]);
    }

    if(currentPostCommenter&& currentPostCommenter.image){
      formData.append('page', currentPostCommenter?.id);
    }else if(currentProfileCommentData){
      formData.append('page', currentProfileCommentData?.id);
    }

    if (mode === 'create') {
      createComment(formData);
    } else if (mode === 'edit') {
      edit(formData);
    }
  };

  const submit = (currentPostCommenter) => {
    handleSubmit(currentPostCommenter);
  };

  const resetForm = () => {
    setImages([]);
    setGifs([]);
    setVideos([]);
    setTagged([]);
    setText('');
    setReplyControl(0);
    setVisibility(0);
    setExpanded(false);
    setCommentBoxExapnded && setCommentBoxExapnded(false);
    setCommentBoxVisible(false);
    if (textInputRef?.current) {
      textInputRef.current.value = '';
    }
  };

  const hasContent = useMemo(() => {
    return (
      text?.length > 0 ||
      images?.length > 0 ||
      videos?.length > 0 ||
      gifs?.length > 0
    );
  }, [text, images, videos, gifs]);

  return {
    text,
    setText,
    images,
    setImages,
    videos,
    setVideos,
    gifs,
    setGifs,
    textInputRef,
    addImage,
    removeImage,
    addVideo,
    removeVideo,
    addGif,
    removeGif,
    replyControl,
    setReplyControl,
    visibility,
    setVisibility,
    textMaxLength: 300,
    submit,
    isLoading,
    expanded,
    setExpanded,
    hasContent,
    showComments,
    level,
    mode,
    setMode,
    parent:parent
  };
};
